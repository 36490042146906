footer {
    padding-top: 2*$padding-large-vertical;
}

.vertical-center {
    display: flex;
    align-items: center;
}

.catalogue img, .post img, .weeknote-image img {
    width: 100%;
    height: auto;
}

.weeknote-image {
    margin: 2em 0;
}

.slide img {
    margin: 1em;
    border: 1px #bbb solid;
}

article {
    margin-bottom: 3em;
}

